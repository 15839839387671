import styled from "styled-components"
import Img from "gatsby-image"
import media from "styled-media-query"
import MuiTabs from "@material-ui/core/Tabs"
import MuiTab from "@material-ui/core/Tab"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  height: 100vh;
  ${media.lessThan("small")`
        grid-template-columns: none;
        grid-auto-flow: row;
        height: auto;
    `}
`

export const CoverColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: #f2f2f2;
  min-width: 0;
  min-height: 0;
`
export const DetailsColumn = styled.div`
  align-self: flex-start;
  padding: 0 6rem 2rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  ${media.lessThan("medium")`
        padding: 2rem 2rem 2rem 1rem
    `}
  ${media.lessThan("small")`
        padding: 2rem 1rem;
        margin:0;
    `}
`

export const ExpansionPanel = styled(MuiExpansionPanel)`
  &&& {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.colors.greys.grey10};
    padding: 0.25rem 0;
    &::before {
      background-color: transparent;
    }
  }
`

export const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)`
  &&& {
    > .MuiExpansionPanelSummary-expandIcon {
      color: ${props => props.theme.colors.blues.blue40};
    }
  }
`

export const Tabs = withStyles({
	indicator: {
		display: "flex",
		justifyContent: "flex-start",
		width: "3rem",
		maxWidth: "3rem",
		backgroundColor: "#e81e61",
		"& > div": {
			width: "3rem"
		}
	}
})(MuiTabs)

export const Tab = withStyles({
	root: {
		paddingLeft: 0,
		paddingRight: "2rem",
		maxWidth: "max-content",
		minWidth: 0
	},
	wrapper: {
		textAlign: "left",
		alignItems: "flex-start"
	}
})(MuiTab)
