import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { Formik, Form as FormikForm, Field } from 'formik'
import * as yup from 'yup'
import { GooSpinner } from 'react-spinners-kit'
import MenuItem from '@material-ui/core/MenuItem'

import StarInput from './StarInput'
import { managementClient } from 'services'
import { Heading, Body, Form, Stack, Button } from 'components'
import { ReviewType, REVIEW_TYPES } from '../'

function Review(
  rating: number,
  review: string,
  name: string,
  date: Moment,
  type: typeof REVIEW_TYPES[number]
) {
  this.rating = rating
  this.review = review
  this.name = name
  this.date = date
  this.type = type
}

const ValidationSchema = yup.object().shape({
  rating: yup.number().moreThan(0, 'Did you forget to select a rating?'),
  review: yup.string().required('This field is required'),
  name: yup.string().required('This field is required')
})

const ReviewForm = ({ addReview, contentfulId }: any) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!isSubmitted) {
    return (
      <Formik
        initialValues={{ rating: 0, name: '', review: '', type: 'Student' }}
        validationSchema={ValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          addReview(
            new Review(
              values.rating,
              values.review,
              values.name,
              moment(),
              values.type as typeof REVIEW_TYPES[number]
            )
          )
          setIsSubmitting(true)
          managementClient
            .getSpace(process.env.GATSBY_CONTENTFUL_SPACE_ID)
            .then((space: any) =>
              space
                .getEnvironment(process.env.GATSBY_CONTENTFUL_ENVIRONMENT)
                .then((environment: any) =>
                  environment.createEntry('reviews', {
                    fields: {
                      rating: {
                        'en-US': values.rating
                      },
                      review: {
                        'en-US': values.review
                      },
                      name: {
                        'en-US': values.name
                      },
                      type: {
                        'en-US': values.type
                      },
                      date: {
                        'en-US': new Date()
                      },
                      book: {
                        'en-US': {
                          sys: {
                            type: 'Link',
                            linkType: 'Entry',
                            id: contentfulId
                          }
                        }
                      }
                    }
                  })
                )
            )
            .then((entry: any) => {
              managementClient
                .getSpace(process.env.GATSBY_CONTENTFUL_SPACE_ID)
                .then((space: any) =>
                  space
                    .getEnvironment(process.env.GATSBY_CONTENTFUL_ENVIRONMENT)
                    .then((environment: any) =>
                      environment.getEntry(entry.sys.id)
                    )
                    .then((entry: any) => entry.publish())
                )
            })
            .then(() => {
              setIsSubmitting(false)
              setIsSubmitted(true)
            })
            .catch(() => {
              actions.setFieldError(
                'review',
                'Something went wrong. Try again later'
              )
              setIsSubmitting(false)
            })
        }}
      >
        {props => (
          <FormikForm
            style={{ marginBottom: '2.5rem' }}
            data-testid="review-form"
          >
            <Heading variant="sm" mb={2}>
              Care to leave us a review?
            </Heading>
            <Body mb={4}>
              Fill in the form below to let us know what you think!
            </Body>
            <Stack grid-row-gap={4}>
              <Stack grid-row-gap={1}>
                <StarInput
                  value={props.values.rating}
                  setRating={props.setFieldValue}
                />
                {props.errors.rating && (
                  <Body color="error" variant="xs" mt={0}>
                    {props.errors.rating}
                  </Body>
                )}
              </Stack>
              <Form.TextField
                multiline
                name="review"
                label="Review"
                id="review-input"
                inputProps={{
                  'data-testid': 'review-input'
                }}
                onChange={props.handleChange}
                error={!!props.errors.review}
                helperText={props.errors.review}
                InputLabelProps={{
                  htmlFor: 'review-input'
                }}
              />

              <Form.TextField
                name="name"
                label="Your Name"
                id="name-input"
                inputProps={{
                  'data-testid': 'name-input'
                }}
                onChange={props.handleChange}
                error={!!props.errors.name}
                helperText={props.errors.name}
                InputLabelProps={{
                  htmlFor: 'review-input'
                }}
              />

              <Field name="type">
                {({ field, form: { touched, errors } }: any) => (
                  <Form.TextField
                    select
                    label="Select One"
                    name="type"
                    {...field}
                  >
                    {REVIEW_TYPES.map(type => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Form.TextField>
                )}
              </Field>

              <Button mt={3} type="submit" variant="outlined">
                {isSubmitting ? (
                  <GooSpinner size={30} color="#5161CB" />
                ) : (
                  'Submit Review'
                )}
              </Button>
            </Stack>
          </FormikForm>
        )}
      </Formik>
    )
  } else {
    return (
      <Heading variant="sm" data-testid="thank-you">
        <span role="img">😍</span> Thank you for leaving a review!
      </Heading>
    )
  }
}

export default ReviewForm
