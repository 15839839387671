import * as React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { ButtonBase } from '@material-ui/core'
import media from 'styled-media-query'

import { PreviewButtonProps } from './types'

export const Cover = styled(Img)`
  border-radius: 10%;
  width: 60%;
  margin: 0 auto;
  box-shadow: ${props => props.theme.shadows[8]};
`
export const Component = styled.div`
  position: sticky;
  height: max-content;
  top: 20px;
  ${media.lessThan('medium')`
		position: relative;
	`}
`

export const PreviewButton = styled(({ active, ...other }) => (
  <ButtonBase {...other} />
))`
  &&& {
    border-radius: 20px;
    background-color: white;
    width: 5rem;
    height: 5rem;
    box-shadow: ${props => props.theme.shadows[8]};
    overflow: hidden;
    transition: all ease 0.3s;
    opacity: ${props => (props.active ? '100%' : '30%')};
    &:hover {
      box-shadow: ${props => props.theme.shadows[4]};
      opacity: 100%;
    }
  }
`

export const Thumbnail = styled(Img)`
  height: 100%;
  width: 100%;
`
