import React, { useState } from 'react'

import Star from './Star'
import { Stack } from 'components'

type Props = {
  value: number
  setRating: (_: number) => void
}

export default function StarInput({ value, setRating }: Props) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleStarHover = (i: number) => {
    if (value === 0) {
      setActiveIndex(i + 1)
    }
  }
  return (
    <Stack
      gridTemplateColumns="repeat(5, min-content)"
      gridColumnGap={2}
      onMouseLeave={() => setActiveIndex(0)}
    >
      {Array.apply(0, Array(5)).map(function(x, i) {
        return (
          <Star
            data-testid={`star-${i + 1}`}
            handleMouseOver={() => handleStarHover(i)}
            key={i}
            active={value >= i + 1 || activeIndex >= i + 1}
            handleClick={() => setRating('rating', i + 1)}
          />
        )
      })}
    </Stack>
  )
}
