import * as React from 'react'

import { Component, Cover, PreviewButton, Thumbnail } from './style'
import { CoverColumnProps, PreviewType } from './types'
import { Stack } from 'components'

const CoverColumn = ({ cover, previews }: CoverColumnProps) => {
  const [displayImage, setDisplayImage] = React.useState(cover.fluid)

  return (
    <Component>
      <Stack gridRowGap={4}>
        <svg
          width="100%"
          height="519"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: 'absolute' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.168 0.17173C321.839 -3.05694 368.001 39.8765 411.022 75.366C451.571 108.815 492.046 146.224 504.987 197.179C517.529 246.562 498.367 296.824 479.264 344.057C461.412 388.196 437.334 429.466 399.404 458.234C360.897 487.441 313.952 498.471 266.168 505.686C210.056 514.158 149.154 531.978 100.108 503.425C49.7297 474.097 26.8171 413.817 11.6559 357.518C-2.9046 303.449 -6.53571 244.661 16.7051 193.719C38.6705 145.573 91.2177 123.925 133.024 91.4887C176.611 57.6713 211.099 3.36551 266.168 0.17173Z"
            fill="#F9D6CA"
          />
        </svg>
        <Cover fluid={displayImage} />
        <Stack gridAutoColumns="5rem" gridAutoFlow="column" m="0 auto">
          {previews.length > 1 && (
            <>
              <PreviewButton
                onClick={() => setDisplayImage(cover.fluid)}
                active={displayImage === cover.fluid}
              >
                <Thumbnail fluid={cover.fluid} />
              </PreviewButton>
              {previews.map((preview: PreviewType) => (
                <PreviewButton
                  key={preview.id}
                  active={displayImage === preview.fluid}
                  onClick={() => setDisplayImage(preview.fluid)}
                >
                  <Thumbnail fluid={preview.fluid} />
                </PreviewButton>
              ))}
            </>
          )}
        </Stack>
      </Stack>
    </Component>
  )
}
export default CoverColumn
