import React from 'react'
import moment from 'moment'

import { Review, Star, List } from './style'
import { Body, Stack } from 'components'

const ReviewList = (props: any) => {
  return (
    <List>
      {props.reviews.map((review: any) => (
        <Review key={review.sys.id} data-testid="review">
          <Stack>
            <div>
              {Array.apply(0, Array(review.fields.rating)).map((e, i) => {
                return <Star key={i} />
              })}
            </div>
            <Body m={0}>{review.fields.review}</Body>
            <Stack gridTemplateColumns="repeat(3, max-content)">
              <Body fontWeight="bold">{review.fields.name}</Body>
              {review.fields.type && <Body>{review.fields.type}</Body>}
              <Body fontStyle="italic">
                {moment(review.fields.date).fromNow()}
              </Body>
            </Stack>
          </Stack>
        </Review>
      ))}
    </List>
  )
}

export default ReviewList
