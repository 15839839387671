import React, { useState, useEffect } from 'react'

import { Link, Body } from 'components'
import ReviewForm from './ReviewForm'
import ReviewList from './ReviewList'
import { client } from 'services/contentful'
import { useMagic } from 'stores'

export const REVIEW_TYPES = [
  'Teacher',
  'Student',
  'Tutor',
  'Parent',
  'Other'
] as const

export type ReviewType = {
  rating: number
  date: string
  type: typeof REVIEW_TYPES[number]
  review: string
  name: string
}

export default function Reviews({ contentfulId }: any) {
  const [reviews, setReviews] = useState([])
  const { isAuthenticated } = useMagic()
  useEffect(() => {
    async function fetchReviews() {
      try {
        const res = await client.getEntries({
          content_type: 'reviews',
          'fields.book.sys.id': contentfulId
        })
        setReviews(res.items)
      } catch (e) {
        console.log(`There's been an error`)
      }
    }
    fetchReviews()
  }, [])

  const addReview = (review: ReviewType) => {
    setReviews([
      {
        sys: { id: Math.random().toString() },
        fields: {
          rating: review.rating,
          name: review.name,
          date: review.date,
          type: review.type,
          review: review.review
        }
      },
      ...reviews
    ])
  }

  return (
    <div>
      {!isAuthenticated ? (
        <Body data-testid="review-login-prompt">
          Would like to leave a review? Please <Link to="/signin">login</Link>{' '}
          first!
        </Body>
      ) : (
        <ReviewForm
          data-testid="review-form"
          addReview={addReview}
          contentfulId={contentfulId}
        />
      )}
      {reviews.length > 0 ? (
        <ReviewList reviews={reviews} bookId={contentfulId} />
      ) : (
        <Body>
          <i>No reviews yet</i>
        </Body>
      )}
    </div>
  )
}
