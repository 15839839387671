import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Heading, Body } from 'components'
import { FAQ } from 'models'

export default function FAQs({ faqs }: { faqs: FAQ[] }) {
  return faqs ? (
    <>
      {faqs.map((faq, index) => (
        <div key={index}>
          <Heading as="h4" variant="sm">
            {faq.question}
          </Heading>
          <ReactMarkdown source={faq.answer.answer} />
        </div>
      ))}
    </>
  ) : (
    <Body fontStyle="italic">No FAQs for this book yet!</Body>
  )
}
