import React from 'react'
import { FaStar, FaRegStar } from 'react-icons/fa'
import styled from 'styled-components'

const FullStar = styled(FaStar)`
  color: #f4c965;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`

const EmptyStar = styled(FaRegStar)`
  color: #f4c965;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`

type Props = {
  active: boolean
  handleClick: (_: any) => void
  handleMouseOver: () => void
}

export default function Star({
  active,
  handleClick,
  handleMouseOver,
  ...rest
}: Props) {
  return active ? (
    <FullStar onClick={handleClick} onMouseOver={handleMouseOver} {...rest} />
  ) : (
    <EmptyStar onClick={handleClick} onMouseOver={handleMouseOver} {...rest} />
  )
}
