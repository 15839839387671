import styled from "styled-components"
import { FiShoppingCart } from "react-icons/fi"

export const Rating = styled.div`
  border-radius: 100px;
  margin-left: 1rem;
  background-color: rgb(232, 30, 97);
  color: white;
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 1fr 1fr;
  > p {
    margin-bottom: 0;
  }
`

export const CartIcon = styled(FiShoppingCart)`
  margin-right: 1rem;
`

export const ButtonWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1rem;
`
