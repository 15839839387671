import { FaStar } from 'react-icons/fa'
import styled from 'styled-components'

export const Star = styled(FaStar)`
	color: #f4c965;
`

export const Review = styled.div`
	padding-bottom: 2rem;
`
export const List = styled.div`
	overflow: scroll;
`
