import React, { useState, useContext, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import moment from 'moment'
import flatten from 'lodash/flatten'

import { ChapterType } from 'models'
import { Stack, Button, Heading, Body, RatingBadge } from 'components'
import { CartIcon } from './style'
import { convertTitle } from 'utils/helpers'
import { BookType } from 'models'
import { useCartStore } from 'stores'

type Props = {
  book: BookType
}

const hasSampleChapters = (chapters: ChapterType[]) => {
  return flatten(chapters.map(chapter => chapter.sections))
    .filter(section => section)
    .some(section => section.isAvailableAsSample)
}

export default function Overview({ book }: Props) {
  const [sampleChapter, setSampleChapter] = useState(null)
  const [cart, addToCart] = useCartStore(state => [state.cart, state.addToCart])
  const {
    reviews,
    title,
    price,
    id,
    description: { description },
    availability,
    availabilityDate,
    chapters
  } = book

  useEffect(() => {
    if (hasSampleChapters(chapters)) {
      setSampleChapter(
        chapters
          .filter((chapter: ChapterType) => chapter.sections)
          .sort((a, b) => a.order - b.order)
          .filter(
            chapter =>
              chapter.sections
                .sort((a, b) => a.order - b.order)
                .filter(section => section.isAvailableAsSample).length > 0
          )[0].order
      )
    }
  }, [])

  return (
    <div data-testid="tab">
      <Stack alignItems="center" gridTemplateColumns="1fr min-content">
        <Heading m={0} as="h2">
          {title}
        </Heading>
        {reviews && <RatingBadge reviews={reviews} />}
      </Stack>
      <Stack
        gridAutoColumns="max-content"
        gridAutoFlow="column"
        alignItems="center"
        my={4}
        gridColumnGap={5}
      >
        <Heading variant="sm" m={0} color="greys.grey70">
          ${price}
        </Heading>
        {availability !== 'preorder' && (
          <Body variant="sm" m={0} fontWeight="bold">
            Purchase now for immediate access
          </Body>
        )}
        {availability === 'preorder' && availabilityDate && (
          <Body
            data-testid="preorder-date"
            variant="sm"
            m={0}
            fontWeight="bold"
          >
            Available {moment(availabilityDate).fromNow()}
          </Body>
        )}
      </Stack>

      <ReactMarkdown source={description} />
      <Body>
        🍃 To reduce our carbon footprint (hoorah for sustainability!), all our
        ebooks are{' '}
        <span style={{ fontWeight: 'bold' }}>online-viewable only</span>. They
        cannot be printed or downloaded as a PDF.
      </Body>
      <Body>
        ⚠ Our LSG study guide app is currently only compatible with{' '}
        <span style={{ fontWeight: 'bold' }}>
          Google Chrome on desktop and mobile
        </span>
        . If you are using Safari or other browsers, you may experience issues.
      </Body>

      <Stack
        gridAutoColumns="max-content"
        gridAutoFlow="column"
        alignItems="center"
        mt={4}
      >
        {cart.some(item => item.contentful_id === book.contentful_id) ? (
          <Button disabled>Added to Cart</Button>
        ) : availability !== 'preorder' ? (
          <Button
            onClick={() =>
              addToCart({
                title: book.title,
                contentful_id: book.contentful_id,
                price: book.price
              })
            }
          >
            <CartIcon /> Add to Cart
          </Button>
        ) : (
          <Button
            onClick={() => {
              addToCart({
                title: book.title,
                contentful_id: book.contentful_id,
                price: book.price
              })
            }}
          >
            <CartIcon /> Preorder
          </Button>
        )}
        {sampleChapter && (
          <Button
            variant="text"
            component={Link}
            to={`/app/book/${convertTitle(title)}/chapter${sampleChapter}/1`}
          >
            Read a Free Sample
          </Button>
        )}
      </Stack>
    </div>
  )
}
