import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { FiChevronDown } from 'react-icons/fi'
import Container from '@material-ui/core/Container'

import Overview from './Overview'
import { Box, Body, Stack, Section, Breadcrumbs } from 'components'
import Contents from './Contents'
import Reviews from './Reviews'
import FAQs from './FAQs'
import CoverColumn from './CoverColumn'
import { BookType, LocationType } from 'models'
import { ExpansionPanel, ExpansionPanelSummary } from './style'
import { trackRecentlyViewedItem } from 'hooks'

type Props = {
  data: {
    contentfulBook: BookType
  }
  location: LocationType
}

const BookPage = (props: Props) => {
  const {
    cover,
    title,
    contentful_id,
    previews,
    contents,
    faqs,
    price
  } = props.data.contentfulBook

  useEffect(() => {
    trackRecentlyViewedItem({
      id: contentful_id,
      title: title,
      price: price
    })
  }, [])
  return (
    <>
      <Breadcrumbs>
        <Link to="/">eBooks</Link>
        <Link to={props.location.pathname}>{title}</Link>
      </Breadcrumbs>
      <Section data-testid="page">
        <Container>
          <Stack
            gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
            gridColumnGap={[0, 5]}
            gridRowGap={[5, 0]}
          >
            <CoverColumn cover={cover} previews={previews} />
            <div>
              <Overview book={props.data.contentfulBook} />
              <Box mt={4}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                    <Body m={0} fontWeight="bold">
                      Contents
                    </Body>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ display: 'block' }}>
                    <Contents source={contents} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ display: 'block' }}>
                  <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                    <Body m={0} fontWeight="bold">
                      Reviews
                    </Body>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ display: 'block' }}>
                    <Reviews contentfulId={contentful_id} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ display: 'block !important' }}>
                  <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                    <Body m={0} fontWeight="bold">
                      FAQ
                    </Body>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ display: 'block' }}>
                    <FAQs faqs={faqs} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<FiChevronDown />}>
                    <Body m={0} fontWeight="bold">
                      Refunds
                    </Body>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Refunds />
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}
              </Box>
            </div>
          </Stack>
        </Container>
      </Section>
    </>
  )
}

export const query = graphql`
  query($contentful_id: String!) {
    contentfulBook(contentful_id: { eq: $contentful_id }) {
      id
      contentful_id
      title
      availability
      availabilityDate
      chapters {
        order
        sections {
          order
          isAvailableAsSample
        }
      }
      faqs {
        question
        answer {
          answer
        }
      }
      contents {
        contents
      }
      reviews {
        id
        rating
      }
      previews {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      price
      description {
        description
      }
      cover {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default BookPage
